export const endpoint = {
  userLogin: `admin/login`,
  logout: `admin/logout`,

  //schooldetails
  getSchoolDetails: `admin/getAllSchools`,
  getSearchSchools:`/admin/SearchSchools?searchString=`,
  editAllotedRice: `/admin/riceAllotment`,
  riceAllotment_detail: `/common/riceAllotmentHistory?schoolId=`,
  getSchoolRegisterDetails:`/common/getRegistersBySchool?month=`,
  //weekschedule
  getweekSchedule: `/admin/getSchedule`,
  editData: `/admin/UpdateSchedule/`,
  getBillsView:`/common/getBillsBySchool?schoolId=`,
};
