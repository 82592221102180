import { Card, DatePicker, Form, Spin, Tag } from "antd";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { getBillsView } from "../../../services/apiFunctions";
import GenerateBill from "./GenerateBill";
import { ExtractDate, getLocalStorageItem } from "../../../utils";
import { useLocation } from "react-router-dom";

const BillDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDataModal, setOpenDataModal] = useState(false);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const userId = getLocalStorageItem("userDetails")?._id;
  const currentYear = moment().year(); // Get current year
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentPage, setcurrentPage] = useState(1);
  const [filledData, setFilledData] = useState();
  const location = useLocation();
  const Fill = location?.state?.Fill;
  const handleDateChange = (date, dateString) => {
    if (date) {
      setSelectedYear(date.year());
      setSelectedDate(date);
    } else {
      setSelectedYear(currentYear);
      setSelectedDate(null);
    }
  };

  const BillsView = () => {
    getBillsView(
      `${Fill._id}&year=${selectedYear}&page=${currentPage}&limit=10`
    )
      .then((res) => {
        setData(res?.data?.result?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    BillsView(currentPage);
  }, [currentPage, selectedYear]);

  const handlePageChange = (page) => {
    setcurrentPage(page);
  };
  const calculateTimeAgo = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);

    const diffInSeconds = Math.floor((now - createdDate) / 1000);
    const days = Math.floor(diffInSeconds / (3600 * 24));
    const hours = Math.floor((diffInSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);

    let timeAgo = "";
    if (days > 0) {
      timeAgo += `${days} day${days > 1 ? "s" : ""}, `;
    }
    if (hours > 0 || days > 0) {
      timeAgo += `${hours} hour${hours > 1 ? "s" : ""}, `;
    }
    timeAgo += `${minutes} minute${minutes > 1 ? "s" : ""} ago`;

    return timeAgo;
  };
  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <div>
          <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
            Bills
          </div>
          <div className="flex justify-end  relative m-5 ">
            <DatePicker
              picker="year"
              placeholder="Select Year"
              onChange={handleDateChange}
              value={selectedDate}
            />
          </div>
          <div>
            {data.length > 0 ? (
              <>
                {data?.map((i, id) => {
                  return (
                    <Card
                      className="m-4 shadow-sm shadow-gray-300"
                      onClick={() => {
                        setOpenDataModal(true);
                        setFilledData(i);
                      }}
                    >
                      <div className=" justify-between  cursor-pointer grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-[5%_20%_25%_25%_25%] items-center">
                        <div>
                          <p>{id + 1}.</p>
                        </div>
                        <div>
                          <p className="font-semibold ">Month</p>
                          <p className="text-gray-500">{i.Month}</p>
                        </div>

                        <div>
                          {" "}
                          <p className="font-semibold "> Year</p>
                          <p className="text-gray-500">{i.Year}</p>
                        </div>

                        <div>
                          <p className="font-semibold ">Generated Date</p>
                          <p className="text-gray-500 text">
                            {ExtractDate(i.createdAt)}
                          </p>
                        </div>
                        <div>
                          <Tag className=" bg-green-300 text-green-700 font-semibold border-green-700 w-48 flex place-content-center">
                            {calculateTimeAgo(i.createdAt)}
                          </Tag>
                        </div>
                      </div>
                    </Card>
                  );
                })}
              </>
            ) : (
              <Card className=" m-4 shadow-sm shadow-gray-300 flex justify-center font-semibold text-red-400">
                Oops! No Bills are Available to shown{" "}
              </Card>
            )}
            {openDataModal && (
              <GenerateBill
                // from={from}
                filledData={filledData}
                // middleData={middleData}
                // apiCall={weeklyScheduleDetails}
                open={openDataModal}
                setOpen={setOpenDataModal}
                // primaryClassID={primaryClassID}
                // middleClassId={middleClassId}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BillDetails;
