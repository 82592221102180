import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import "dayjs/locale/en"; // Import English locale if it's not your default
import { editData } from "../../../services/apiFunctions";
dayjs.extend(customParseFormat);

const AddData = ({
  open,
  setOpen,
  from,
  apiCall,
  primaryData,
  primaryClassID,
  middleClassId,
  middleData,
}) => {
  const [form] = Form.useForm();
  const formref = useRef();
  const [btnLoading, setBtnLoading] = useState(false);

  const weekDays = [
    { label: "Monday", value: "Mon" },
    { label: "Tuesday", value: "Tues" },
    { label: "Wednesday", value: "Wednes" },
    { label: "Thursday", value: "Thurs" },
    { label: "Friday", value: "Fri" },
    { label: "Saturday", value: "Satur" },
    { label: "Sunday", value: "Sun" },
  ];

  const convertToNumber = (value) => {
    if (typeof value === "string" && !isNaN(value)) {
      return Number(value);
    }
    return value;
  };

  const deepConvert = (obj) => {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }
    if (Array.isArray(obj)) {
      return obj.map(deepConvert);
    }
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = deepConvert(convertToNumber(obj[key]));
      return acc;
    }, {});
  };

  const handleAdd = (valuesArray) => {
    setBtnLoading(true);

    // Deep convert values to numbers where applicable
    const payload = deepConvert(valuesArray);

    editData(
      payload,
      from === "primaryClass" ? `${primaryClassID}` : `${middleClassId}`
    )
      .then((res) => {
        if (res.status) {
          setOpen(false);
          apiCall();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };

  useEffect(() => {
    if (primaryData && from === "primaryClass") {
      const initialValues = {
        days: primaryData.map((item) => ({
          _id: item._id,
          name: item.name,
          items: {
            Pulses: {
              quantity: item.items.Pulses.quantity,
              amount: item.items.Pulses.amount,
              _id: item.items.Pulses._id,
            },
            Salt_Spices: {
              quantity: item.items.Salt_Spices.quantity,
              amount: item.items.Salt_Spices.amount,
              _id: item.items.Salt_Spices._id,
            },
            vegetables: {
              quantity: item.items.vegetables.quantity,
              amount: item.items.vegetables.amount,
              _id: item.items.vegetables._id,
            },
            Oli_Butter: {
              quantity: item.items.Oli_Butter.quantity,
              amount: item.items.Oli_Butter.amount,
              _id: item.items.Oli_Butter._id,
            },
            Fuel: {
              quantity: item.items.Fuel.quantity,
              amount: item.items.Fuel.amount,
              _id: item.items.Fuel._id,
            },
          },
        })),
      };
      form.setFieldsValue(initialValues);
      console.log(initialValues, "initialValues");
    } else if (middleData && from === "middleClass") {
      const initialValues = {
        days: middleData.map((item) => ({
          _id: item._id,
          name: item.name,
          items: {
            Pulses: {
              quantity: item.items.Pulses.quantity,
              amount: item.items.Pulses.amount,
              _id: item.items.Pulses._id,
            },
            Salt_Spices: {
              quantity: item.items.Salt_Spices.quantity,
              amount: item.items.Salt_Spices.amount,
              _id: item.items.Salt_Spices._id,
            },
            vegetables: {
              quantity: item.items.vegetables.quantity,
              amount: item.items.vegetables.amount,
              _id: item.items.vegetables._id,
            },
            Oli_Butter: {
              quantity: item.items.Oli_Butter.quantity,
              amount: item.items.Oli_Butter.amount,
              _id: item.items.Oli_Butter._id,
            },
            Fuel: {
              quantity: item.items.Fuel.quantity,
              amount: item.items.Fuel.amount,
              _id: item.items.Fuel._id,
            },
          },
        })),
      };
      form.setFieldsValue(initialValues);
    }
  }, [primaryData, form]);

  return (
    <Modal
      style={{ width: "700px" }}
      title={from === "primaryClass" ? "Edit Primary Data" : "Edit Middle Data"}
      open={open}
      maskClosable={false}
      footer={null}
      onCancel={() => {
        setOpen(false);
      }}
    >
      <div className="h-[400px]  overflow-y-scroll">
        <Form ref={form} form={form} onFinish={handleAdd}>
          <Form.List name="days">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <div key={key}>
                    <div className="grid grid-cols-1 sm:grid-cols-1 place-items-center gap-x-2">
                      <Form.Item
                        {...restField}
                        label="दिन"
                        labelCol={{ span: 24 }}
                        className="w-full"
                        rules={[{ required: true, message: "दिन is required" }]}
                        name={[name, "name"]}
                        fieldKey={[fieldKey, "name"]}
                      >
                        <Select options={weekDays} placeholder="दिन" />
                      </Form.Item>
                    </div>
                    <div className="flex justify-between gap-8">
                      <div>
                        <p>तेल</p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center gap-x-2">
                          <Form.Item
                            {...restField}
                            label="मात्रा"
                            labelCol={{ span: 24 }}
                            className="w-full"
                            rules={[
                              { required: true, message: "Matra is required" },
                              {
                                pattern: /^(?:0|[1-9]\d*)(?:\.\d+)?$/,
                                message: "Enter a number greater than 0",
                              },
                            ]}
                            name={[name, "items", "Oli_Butter", "quantity"]}
                            fieldKey={[
                              fieldKey,
                              "items",
                              "Oli_Butter",
                              "quantity",
                            ]}
                          >
                            <Input type="number" placeholder="Matra" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label="राशि"
                            labelCol={{ span: 24 }}
                            className="w-full"
                            rules={[
                              { required: true, message: "Rashi is required" },
                              {
                                pattern: /^(?:0|[1-9]\d*)(?:\.\d+)?$/,
                                message: "Enter a number greater than 0",
                              },
                            ]}
                            name={[name, "items", "Oli_Butter", "amount"]}
                            fieldKey={[
                              fieldKey,
                              "items",
                              "Oli_Butter",
                              "amount",
                            ]}
                          >
                            <Input type="number" placeholder="Rashi" />
                          </Form.Item>
                        </div>
                      </div>
                      <div>
                        <p>मसाला/नमक</p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center gap-x-2">
                          <Form.Item
                            {...restField}
                            label="मात्रा"
                            labelCol={{ span: 24 }}
                            className="w-full"
                            rules={[
                              { required: true, message: "Matra is required" },
                              {
                                pattern: /^(?:0|[1-9]\d*)(?:\.\d+)?$/,
                                message: "Enter a number greater than 0",
                              },
                            ]}
                            name={[name, "items", "Salt_Spices", "quantity"]}
                            fieldKey={[
                              fieldKey,
                              "items",
                              "Salt_Spices",
                              "quantity",
                            ]}
                          >
                            <Input type="number" placeholder="Matra" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label="राशि"
                            labelCol={{ span: 24 }}
                            className="w-full"
                            rules={[
                              { required: true, message: "Rashi is required" },
                              {
                                pattern: /^(?:0|[1-9]\d*)(?:\.\d+)?$/,
                                message: "Enter a number greater than 0",
                              },
                            ]}
                            name={[name, "items", "Salt_Spices", "amount"]}
                            fieldKey={[
                              fieldKey,
                              "items",
                              "Salt_Spices",
                              "amount",
                            ]}
                          >
                            <Input type="number" placeholder="Rashi" />
                          </Form.Item>
                        </div>
                      </div>
                      <div>
                        <p>दाल</p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center gap-x-2">
                          <Form.Item
                            {...restField}
                            label="मात्रा"
                            labelCol={{ span: 24 }}
                            className="w-full"
                            rules={[
                              { required: true, message: "Matra is required" },
                              {
                                pattern: /^(?:0|[1-9]\d*)(?:\.\d+)?$/,
                                message: "Enter a number greater than 0",
                              },
                            ]}
                            name={[name, "items", "Pulses", "quantity"]}
                            fieldKey={[fieldKey, "items", "Pulses", "quantity"]}
                          >
                            <Input type="number" placeholder="Matra" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label="राशि"
                            labelCol={{ span: 24 }}
                            className="w-full"
                            rules={[
                              { required: true, message: "Rashi is required" },
                              {
                                pattern: /^(?:0|[1-9]\d*)(?:\.\d+)?$/,
                                message: "Enter a number greater than 0",
                              },
                            ]}
                            name={[name, "items", "Pulses", "amount"]}
                            fieldKey={[fieldKey, "items", "Pulses", "amount"]}
                          >
                            <Input type="number" placeholder="Rashi" />
                          </Form.Item>
                        </div>
                      </div>
                      <div>
                        <p>सब्जी</p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center gap-x-2">
                          <Form.Item
                            {...restField}
                            label="मात्रा"
                            labelCol={{ span: 24 }}
                            className="w-full"
                            rules={[
                              { required: true, message: "Matra is required" },
                              {
                                pattern: /^(?:0|[1-9]\d*)(?:\.\d+)?$/,
                                message: "Enter a number greater than 0",
                              },
                            ]}
                            name={[name, "items", "vegetables", "quantity"]}
                            fieldKey={[
                              fieldKey,
                              "items",
                              "vegetables",
                              "quantity",
                            ]}
                          >
                            <Input type="number" placeholder="Matra" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label="राशि"
                            labelCol={{ span: 24 }}
                            className="w-full"
                            rules={[
                              { required: true, message: "Rashi is required" },
                              {
                                pattern: /^(?:0|[1-9]\d*)(?:\.\d+)?$/,
                                message: "Enter a number greater than 0",
                              },
                            ]}
                            name={[name, "items", "vegetables", "amount"]}
                            fieldKey={[
                              fieldKey,
                              "items",
                              "vegetables",
                              "amount",
                            ]}
                          >
                            <Input type="number" placeholder="Rashi" />
                          </Form.Item>
                        </div>
                      </div>
                      <div>
                        <p>ईंधन</p>
                        <div className="grid grid-cols-1 sm:grid-cols-2 place-items-center gap-x-2">
                          <Form.Item
                            {...restField}
                            label="मात्रा"
                            labelCol={{ span: 24 }}
                            className="w-full"
                            rules={[
                              { required: true, message: "Matra is required" },
                              {
                                pattern: /^(?:0|[1-9]\d*)(?:\.\d+)?$/,
                                message: "Enter a number greater than 0",
                              },
                            ]}
                            name={[name, "items", "Fuel", "quantity"]}
                            fieldKey={[fieldKey, "items", "Fuel", "quantity"]}
                          >
                            <Input type="number" placeholder="Matra" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            label="राशि"
                            labelCol={{ span: 24 }}
                            className="w-full"
                            rules={[
                              { required: true, message: "Rashi is required" },
                              {
                                pattern: /^(?:0|[1-9]\d*)(?:\.\d+)?$/,
                                message: "Enter a number greater than 0",
                              },
                            ]}
                            name={[name, "items", "Fuel", "amount"]}
                            fieldKey={[fieldKey, "items", "Fuel", "amount"]}
                          >
                            <Input type="number" placeholder="Rashi" />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </Form.List>
          {/* <div className="flex justify-center items-center">
            <Form.Item>
              <Button htmlType="submit" className="bg-black text-white">
                {primaryData ? "Update" : "Add"}
              </Button>
            </Form.Item>
          </div> */}
          <Form.Item className="w-full">
            <Button
              type="primary"
              htmlType="submit"
              className="w-full"
              loading={btnLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AddData;
