import React, { useEffect, useState } from "react";
import { Spin, Table, Button } from "antd";
import { getweekSchedule } from "../../../services/apiFunctions";
import Column from "antd/es/table/Column";
import ColumnGroup from "antd/es/table/ColumnGroup";
import { EditOutlined } from "@ant-design/icons";
import AddData from "./AddData";
function WeeklySchedule() {
  const [primaryData, setPrimaryData] = useState([]);
  const [primaryClassID, setprimaryClassID] = useState("");
  const [middleClassId, setmiddleClassId] = useState(" ");
  const [middleData, setMiddleData] = useState([]);
  const [classType, setClassType] = useState("");
  const [openDataModal, setOpenDataModal] = useState(false);
  const [from, setfrom] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [filldata, setFilldata] = useState([]);

  function weeklyScheduleDetails(currentPage) {
    getweekSchedule(`?page=${currentPage}&limit=10`)
      .then((res) => {
        if (res.data?.success) {
          const data = res.data?.data?.map((i, index, arr) => {
            setClassType(i.classType);
            if (index === 0) {
              setprimaryClassID(i._id);
            } else if (index === 1) {
              setmiddleClassId(i._id);
            }

            if (i.classType === "PrimaryClass") {
              const daysData = i.days?.map((e, id) => {
                return e;
              });

              setPrimaryData([...daysData]);
            } else if (i.classType === "MiddleClass") {
              const daysData = i.days?.map((e, id) => {
                return e;
              });
              setMiddleData([...daysData]);
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }
  console.log(primaryData, "primaryData");

  const cancel = (e) => {
    console.log(e);
  };

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    weeklyScheduleDetails(currentPage);
  }, [currentPage]);

  return (
    <div>
      <>
        {isLoading ? (
          <div className="text-center mt-52">
            <Spin size="md" />
          </div>
        ) : (
          <>
            <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
              Schedule
            </div>

            <div>
              <div className=" justify-between m-5 grid grid-cols-1  sm:grid-cols-3">
                <p className="text-sm font-semibold">
                  Primary Class Weekly Schedule
                </p>
                <p></p>
                <Button
                  className="bg-primary text-white responsive-button"
                  onClick={() => {
                    setFilldata(primaryData);
                    console.log(primaryData);
                    setfrom("primaryClass");
                    setOpenDataModal(true);
                  }}
                >
                  <EditOutlined />
                  Edit Primary Class Schedule{" "}
                </Button>
              </div>
            </div>
            <div
              style={{ maxHeight: "500px", overflow: "auto" }}
             
            >
              <Table
                className="history-table animate-fade-in max-h-[500px] mt-4 text-white rounded-lg custom-table-header"
                bordered
                pagination={false}
                dataSource={primaryData}
              >
                <Column
                  title="S.No."
                  key="sno"
                  render={(text, record, index) => <p>{index + 1}</p>}
                  className="green-header"
                />
                <Column
                  // render={(text, record) => (
                  //   <p className="w-[80px]">
                  //     {record.last
                  //       ? ""
                  //       : dayjs.unix(text / 1000).format("DD/MM/YYYY")}
                  //   </p>
                  // )}
                  title="दिन"
                  dataIndex="name"
                  key="name"
                  className="green-header"
                />

                <ColumnGroup title="तेल" className="orange-header">
                  <Column
                    className="blue-header"
                    title="मात्रा"
                    dataIndex="Oli_Butter_quantity"
                    key="Oli_Butter_quantity"
                    render={(data, record) => {
                      return <p>{record.items.Oli_Butter.quantity}</p>;
                    }}
                  />
                  <Column
                    className="blue-header"
                    title="राशि"
                    dataIndex="Oli_Butter_amount"
                    key="Oli_Butter_amount"
                    render={(data, record) => {
                      return <p>{record.items?.Oli_Butter.amount}</p>;
                    }}
                  />
                </ColumnGroup>
                <ColumnGroup title="मसाला/नमक" className="orange-header">
                  <Column
                    className="blue-header"
                    title="मात्रा"
                    dataIndex="Salt_Spices_quantity"
                    key="Salt_Spices_quantity"
                    render={(data, record) => {
                      return <p>{record.items?.Salt_Spices.quantity}</p>;
                    }}
                  />
                  <Column
                    className="blue-header"
                    title="राशि"
                    dataIndex="Salt_Spices_amount"
                    key="Salt_Spices_amount"
                    render={(data, record) => {
                      return <p>{record.items?.Salt_Spices.amount}</p>;
                    }}
                  />
                </ColumnGroup>

                <ColumnGroup title="ईंधन" className="orange-header">
                  <Column
                    className="blue-header"
                    title="मात्रा"
                    dataIndex="Fuel_quantity"
                    key="Fuel_quantity"
                    render={(data, record) => {
                      return <p>{record.items?.Fuel.quantity}</p>;
                    }}
                  />
                  <Column
                    className="blue-header"
                    title="राशि"
                    dataIndex="Fuel_amount"
                    key="Fuel_amount"
                    render={(data, record) => {
                      return <p>{record.items?.Fuel.amount}</p>;
                    }}
                  />
                </ColumnGroup>
                <ColumnGroup title="दालें" className="orange-header">
                  <Column
                    className="blue-header"
                    title="मात्रा"
                    dataIndex="Pulses_quantity"
                    key="Pulses_quantity"
                    render={(data, record) => {
                      return <p>{record.items?.Pulses.quantity}</p>;
                    }}
                  />
                  <Column
                    className="blue-header"
                    title="राशि"
                    dataIndex="Pulses_amount"
                    key="Pulses_amount"
                    render={(data, record) => {
                      return <p>{record.items?.Pulses.amount}</p>;
                    }}
                  />
                </ColumnGroup>
                <ColumnGroup title="सब्ज़ियाँ" className="orange-header">
                  <Column
                    className="blue-header"
                    title="मात्रा"
                    dataIndex="vegetables_quantity"
                    key="vegetables_quantity"
                    render={(data, record) => {
                      return <p>{record.items.vegetables.quantity}</p>;
                    }}
                  />
                  <Column
                    className="blue-header"
                    title="राशि"
                    dataIndex="vegetables_amount"
                    key="vegetables_amount"
                    render={(data, record) => {
                      return <p>{record.items?.vegetables.amount}</p>;
                    }}
                  />
                </ColumnGroup>

                <Column
                  className="green-header"
                  title="कुल व्यय"
                  dataIndex="totalAmount"
                  key="totalAmount"
                />
              </Table>
            </div>

            <div>
              <div className=" justify-between m-5 grid grid-cols-1  sm:grid-cols-3">
                <p className="text-sm font-semibold">
                  Middle Class Weekly Schedule
                </p>
                <p></p>
                <Button
                  className="bg-primary text-white responsive-button"
                  onClick={() => {
                    setFilldata(middleData);
                    console.log(middleData);
                    setfrom("middleClass");
                    setOpenDataModal(true);
                  }}
                >
                  <EditOutlined /> Edit Middle Class Schedule{" "}
                </Button>
              </div>
            </div>
            <div
              style={{ maxHeight: "500px", overflow: "auto" }}
             
            >
              <Table
                className="history-table animate-fade-in max-h-[500px] mt-4 text-white rounded-lg custom-table-header "
                bordered
                pagination={false}
                dataSource={middleData}
              >
                <Column
                  title="S.No."
                  key="sno"
                  render={(text, record, index) => <p>{index + 1}</p>}
                  className="green-header"
                />
                <Column
                  title="दिन"
                  dataIndex="name"
                  key="name"
                  className="green-header"
                />

                <ColumnGroup title="तेल" className="orange-header">
                  <Column
                    className="blue-header"
                    title="मात्रा"
                    dataIndex="Oli_Butter_quantity"
                    key="Oli_Butter_quantity"
                    render={(data, record) => {
                      return <p>{record.items.Oli_Butter.quantity}</p>;
                    }}
                  />
                  <Column
                    className="blue-header"
                    title="राशि"
                    dataIndex="Oli_Butter_amount"
                    key="Oli_Butter_amount"
                    render={(data, record) => {
                      return <p>{record.items?.Oli_Butter.amount}</p>;
                    }}
                  />
                </ColumnGroup>
                <ColumnGroup title="मसाला/नमक" className="orange-header">
                  <Column
                    className="blue-header"
                    title="मात्रा"
                    dataIndex="Salt_Spices_quantity"
                    key="Salt_Spices_quantity"
                    render={(data, record) => {
                      return <p>{record.items?.Salt_Spices.quantity}</p>;
                    }}
                  />
                  <Column
                    className="blue-header"
                    title="राशि"
                    dataIndex="Salt_Spices_amount"
                    key="Salt_Spices_amount"
                    render={(data, record) => {
                      return <p>{record.items?.Salt_Spices.amount}</p>;
                    }}
                  />
                </ColumnGroup>

                <ColumnGroup title="ईंधन" className="orange-header">
                  <Column
                    className="blue-header"
                    title="मात्रा"
                    dataIndex="Fuel_quantity"
                    key="Fuel_quantity"
                    render={(data, record) => {
                      return <p>{record.items?.Fuel.quantity}</p>;
                    }}
                  />
                  <Column
                    className="blue-header"
                    title="राशि"
                    dataIndex="Fuel_amount"
                    key="Fuel_amount"
                    render={(data, record) => {
                      return <p>{record.items?.Fuel.amount}</p>;
                    }}
                  />
                </ColumnGroup>
                <ColumnGroup title="दालें" className="orange-header">
                  <Column
                    className="blue-header"
                    title="मात्रा"
                    dataIndex="Pulses_quantity"
                    key="Pulses_quantity"
                    render={(data, record) => {
                      return <p>{record.items?.Pulses.quantity}</p>;
                    }}
                  />
                  <Column
                    className="blue-header"
                    title="राशि"
                    dataIndex="Pulses_amount"
                    key="Pulses_amount"
                    render={(data, record) => {
                      return <p>{record.items?.Pulses.amount}</p>;
                    }}
                  />
                </ColumnGroup>
                <ColumnGroup title="सब्ज़ियाँ" className="orange-header">
                  <Column
                    className="blue-header"
                    title="मात्रा"
                    dataIndex="vegetables_quantity"
                    key="vegetables_quantity"
                    render={(data, record) => {
                      return <p>{record.items.vegetables.quantity}</p>;
                    }}
                  />
                  <Column
                    className="blue-header"
                    title="राशि"
                    dataIndex="vegetables_amount"
                    key="vegetables_amount"
                    render={(data, record) => {
                      return <p>{record.items?.vegetables.amount}</p>;
                    }}
                  />
                </ColumnGroup>

                <Column
                  className="green-header"
                  title="कुल व्यय"
                  dataIndex="totalAmount"
                  key="totalAmount"
                />
              </Table>
            </div>

            {openDataModal && (
              <AddData
                from={from}
                primaryData={primaryData}
                middleData={middleData}
                apiCall={weeklyScheduleDetails}
                open={openDataModal}
                setOpen={setOpenDataModal}
                primaryClassID={primaryClassID}
                middleClassId={middleClassId}
              />
            )}
          </>
        )}
      </>
    </div>
  );
}
export default WeeklySchedule;
