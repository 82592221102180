import {
  Delete,
  Get,
  Patch,
  Post,
  getAPIUrl,
  PostFormData,
  Put,
} from "./apiMethod";
import { endpoint } from "./global";

export const userLogin = (data, param = "") => {
  const url = getAPIUrl(endpoint.userLogin, param);
  return Post(url, data, false);
};
export const logout = (data, param = "") => {
  const url = getAPIUrl(endpoint.logout, param);
  return Post(url, data);
};

export const getSchoolDetails = (param = "") => {
  const url = getAPIUrl(endpoint.getSchoolDetails, param);
  return Get(url);
};
export const getSearchSchools = (param = "") => {
  const url = getAPIUrl(endpoint.getSearchSchools, param);
  return Get(url);
};
export const getSchoolRegisterDetails = (param = "") => {
  const url = getAPIUrl(endpoint.getSchoolRegisterDetails, param);
  return Get(url);
};
export const getweekSchedule = (param = "") => {
  const url = getAPIUrl(endpoint.getweekSchedule, param);
  return Get(url);
};
export const editData = (data, param = "") => {
  const url = getAPIUrl(endpoint.editData, param);
  return Put(url, data);
};
export const riceAllotment_detail = (param = "") => {
  const url = getAPIUrl(endpoint.riceAllotment_detail, param);
  return Get(url);
};

export const editAllotedRice = (data, param = "") => {
  const url = getAPIUrl(endpoint.editAllotedRice, param);
  return Post(url, data);
};
export const getBillsView = ( param = "") => {
  const url = getAPIUrl(endpoint.getBillsView, param);
  return Get(url);
};
