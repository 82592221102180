import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Menu, Button, message } from "antd";
import { Images } from "../assets";
import {
  MdAccountBalance,
  MdDashboard,
  MdMenuOpen,
  MdMoney,
  MdNewspaper,
  MdOutlineGames,
  MdOutlineLiveTv,
  MdOutlineSupervisedUserCircle,
  MdOutlineWallet,
  MdPause,
  MdSettings,
} from "react-icons/md";
import SubMenu from "antd/es/menu/SubMenu";
import { CheckCircleOutlined } from "@ant-design/icons";
import { logout } from "../services/apiFunctions";
const { Header, Sider, Content } = Layout;
const BasicLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 600 ? setCollapsed(true) : setCollapsed(false);
    };
    window.addEventListener("resize", handleResize);
    const stored = localStorage.getItem("selected");
    if (stored) {
      setSelectedMenu(stored);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    localStorage.setItem("selected", selectedMenu);
  }, [selectedMenu]);
  function getlogout() {
    logout()
      .then((response) => {
        if (response.status) {
          localStorage.clear();
          navigate("/");
          message.success({ content: response.data.message });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <Layout className="min-w-screen sidebar">
      <Sider
        style={{ position: "fixed", height: "100%", overflowY: "auto" }}
        collapsible
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
      >
        <div className="demo-logo-vertical d-flex justify-content-center m-2">
          {!collapsed ? (
            <img
              src={Images.whitebg}
              alt="logo"
              className="w-full mt-9  h-28 flex justify-center  items-center"
            />
          ) : (
            <img
              src={Images.smalllogo}
              alt="logo"
              className="px-2 mt-9"
              style={{ display: "flex" }}
              width={"100px"}
            />
          )}
        </div>
        <Menu
          theme="dark"
          mode="inline"
          className="text-base mt-9 pb-24"
          selectedKeys={[selectedMenu]}
        >
          <Menu.Item
            key="1"
            icon={<MdDashboard />}
            onClick={() => {
              setSelectedMenu("1");
              navigate("/schoolDetails");
            }}
          >
            Schools
          </Menu.Item>
          <Menu.Item
            key="2"
            icon={<MdOutlineSupervisedUserCircle />}
            onClick={() => {
              setSelectedMenu("2");
              navigate("/weeklySchedule");
            }}
          >
       Weekly Schedule
          </Menu.Item>
          {/* <SubMenu
            key="sub1"
            icon={<MdMoney />}
            title="Requests"
            onTitleClick={() => {
              setSelectedMenu("3");
            }}
          >
           
            <Menu.Item
              key="3.1"
              onClick={() => {
                setSelectedMenu("3.1");
                navigate("/withdrawRequests")}}
              title=" Withdraw Requests"
              className="text-sm"
            >
              Withdraw Requests
            </Menu.Item>
            <Menu.Item
              key="3.2"
              onClick={() =>{
                setSelectedMenu("3.2");
                navigate("/kycDetails")}}
              title=" KYC Requests"
              className="text-sm"
            >
              KYC Requests
            </Menu.Item>
            <Menu.Item
              key="3.3"
              onClick={() => {
                setSelectedMenu("3.3");
                navigate("/battleRequests")}}
              title=" Battle Requests"
              className="text-sm"
            >
              Battle Requests
            </Menu.Item>
          </SubMenu> */}
          {/* <Menu.Item
            key="4"
            icon={<MdOutlineLiveTv />}
            onClick={() => {
              setSelectedMenu("4");
              navigate("/liveBattles");
            }}
          >
            Live Battles
          </Menu.Item>
          <Menu.Item
            key="5"
            icon={<MdOutlineWallet />}
            onClick={() => {
              setSelectedMenu("5");
              navigate("/walletDetails");
            }}
          >
            Wallet Details
          </Menu.Item>
          <Menu.Item
            key="6"
            icon={<MdOutlineGames />}
            onClick={() => {
              setSelectedMenu("6");
              navigate("/liveTournaments");
            }}
          >
            Live Tournament
          </Menu.Item>
          <Menu.Item
            key="7"
            icon={<MdPause />}
            onClick={() => {
              setSelectedMenu("7");
              navigate("/holdBattles");
            }}
          >
            Hold Battles
          </Menu.Item>
          <Menu.Item
            key="8"
            icon={<MdSettings />}
            onClick={() => {
              setSelectedMenu("8");
              navigate("/settings");
            }}
          >
            Settings
          </Menu.Item>
          <Menu.Item
            key="9"
            icon={<MdNewspaper />}
            onClick={() => {
              setSelectedMenu("9");
              navigate("/addNews");
            }}
          >
            News
          </Menu.Item>
          <Menu.Item
            key="10"
            icon={<MdMoney />}
            onClick={() => {
              setSelectedMenu("10");
              navigate("/todayWithdrawl");
            }}
          >
            Today Withdrawal
          </Menu.Item> */}
          {/* <Menu.Item
            key="11"
            icon={<MdAccountBalance />}
            onClick={() => {
              setSelectedMenu("11");
              navigate("/refferalPenaltyBonus");
            }}
          >
         Refferal Penalty Bonus
          </Menu.Item>
          <Menu.Item
            key="12"
            icon={<CheckCircleOutlined />}
            onClick={() => {
              setSelectedMenu("12");
              navigate("/completeBattle");
            }}
          >
        Complete Battles
          </Menu.Item> */}
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ marginLeft: collapsed ? 110 : 230, marginTop: 20 }}>
          <div className="flex bg-rgbaprimary3 justify-between px-2 items-center">
            {" "}
            <Button
              type="text"
              icon={
                collapsed ? (
                  <MdMenuOpen className="text-black" />
                ) : (
                  <MdMenuOpen className="text-black" />
                )
              }
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "22px",
                width: 50,
                height: 50,
                alignSelf: "flex-start",
              }}
            />{" "}
            {/* <Button
              className="bg-rgbaprimary9 hover:bg-rgbaprimary5 border-none outline-none text-xs text-white"
             
              onClick={getlogout}
            >
              Log Out
            </Button> */}
            <p
              onClick={getlogout}
              className="bg-rgbaprimary9 border-none outline-none text-xs text-white cursor-pointer  px-4 py-2 text-center rounded-md hover:bg-bssecondary"
            >
              Log Out{" "}
            </p>
          </div>
        </Header>
        <Content
          className={`bg-rgbaprimary3 h-full p-3 md:p-5 ${
            collapsed ? "ml-[110px]" : "ml-[230px]"
          }`}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default BasicLayout;
