import PrivateAuth from "./PrivateAuth";
import SchoolDetails from "../Views/Main Panel/SchoolDetails";
import WeeklySchedule from "../Views/Main Panel/WeeklySchedule";
import RiceAlotmentHistory from "../Views/Main Panel/SchoolDetails/RiceAlotmentHistory";
import BillDetails from "../Views/Main Panel/SchoolDetails/BillDetails";
import RegisterDetails from "../Views/Main Panel/SchoolDetails/RegisterDetails";

const privateAdminRoute = () => {
  return [
    {
      element: <PrivateAuth />,
      children: [
        {
          path: "/schoolDetails",
          element: <SchoolDetails />,
        },
        {
          path: "/riceAlotmentHistory",
          element: <RiceAlotmentHistory />,
        },
        {
          path: "/billDetails",
          element: <BillDetails />,
        },
        {
          path: "/registerDetails",
          element: <RegisterDetails />,
        },
        {
          path: "/weeklySchedule",
          element: <WeeklySchedule />,
        },
      ],
    },
  ];
};

export default privateAdminRoute;
