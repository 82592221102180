import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setLocalStorageItem } from "../../../../src/utils/index";
import { Button, Form, Input, message } from "antd";
import { userLogin } from "../../../services/apiFunctions";
import { Images } from "../../../assets";

const Login = () => {
  const navigate = useNavigate();
  const [fields, setFields] = useState({
    user_name: "",
    password: "",
  });
  const [btnLoading, setBtnLoading] = useState(false);

  const handleLogin = (val) => {
    setBtnLoading(true);
    userLogin(val)
      .then((res) => {
        setLocalStorageItem("access_token", res.data?.token);
        setLocalStorageItem("login", true);
        navigate("/schoolDetails");
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="flex justify-center h-screen items-center bg-rgbaprimary2">
        <div className="shadow-lg m-4 shadow-gray-400 rounded-md w-[400px] p-4">
          <div className="bg-primary rounded-md flex flex-col justify-center items-center">
            <img src={Images.whitebg} className="mt-4 w-28 h-8" />
            <p className="text-center text-white mb-4">Login</p>
          </div>
          <Form
            onFinish={(val) => handleLogin(val)}
            onFinishFailed={(err) => console.log(err)}
          >
            <Form.Item
              label="Username"
              labelCol={{ span: 24 }}
              name="userName"
              rules={[
                {
                  required: true,
                  message: "Enter userName",
                },
              ]}
            >
              <Input name="userName" placeholder="userName" />
            </Form.Item>
            <Form.Item
              label="password"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Enter password",
                },
              ]}
              name="password"
            >
              <Input.Password name="password" placeholder="Passweord" />
            </Form.Item>
            <Form.Item>
              <Button
                loading={btnLoading}
                style={{ color: "white" }}
                htmlType="submit"
                className="w-full mt-2 bg-primary"
              >
                lOGIN
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
