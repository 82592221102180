import { useEffect, useState } from "react";
import {
  Divider,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Menu,
  Pagination,
  Popconfirm,
  Spin,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import {
  editAllotedRice,
  getSchoolDetails,
  getSearchSchools,
} from "../../../services/apiFunctions";
import { ExtractDate, ExtractTime } from "../../../utils";
import { useNavigate } from "react-router-dom";

const SchoolDetails = () => {
  const [currentPage, setcurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record._id === editingKey;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { Search } = Input;
  const [searchTerm, setSearchTerm] = useState("");

  const fetchSchoolDetails = (currentPage) => {
    setIsLoading(true);
    getSchoolDetails(`?page=${currentPage}&limit=${10}`)
      .then((response) => {
        if (response.data.success) {
          setData(response.data?.result?.data);
          setcurrentPage(response.data?.result?.currentPage);
          setTotalPages(response.data?.result?.totalPages);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error fetching school details:", error);
      });
  };

  useEffect(() => {
    if (searchTerm === "") {
      fetchSchoolDetails(currentPage);
    } else {
      getSearchSchools(`${searchTerm}&page=${currentPage}&limit=10`)
        .then((response) => {
          if (response.data.success) {
            console.log(response);
            setData(response.data?.result?.data);
            // const mailData = response.data.data.map((item) => item.email_id)
            // dispatch(setContactMail(mailData))
            // setShowSkeleton(false)

            setcurrentPage(response.data?.result?.currentPage);
            setTotalPages(response.data?.result?.totalPages);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [searchTerm, currentPage]);
  useEffect(() => {
    fetchSchoolDetails(currentPage);
  }, [currentPage]);

  const handlePageChange = (page, pageSize) => {
    setcurrentPage(page);
  };

  const edit = (record, index) => {
    form.setFieldsValue({
      prapt_chawal: record.prapt_chawal,
      ...record,
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item._id);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });

        const payload = {
          quantity: row.prapt_chawal,
          schoolId: item._id,
        };

        setIsLoading(true);
        editAllotedRice(payload)
          .then((response) => {
            if (response.data.success) {
              message.success(response.data?.message);
              fetchSchoolDetails();
              setEditingKey(""); // Reset editing key to hide save/cancel buttons
            } else {
              message.error("Failed to save data");
            }
          })
          .catch((error) => {
            setIsLoading(false);
            console.error("Error saving data:", error);
            message.error("Error saving data");
          });
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
      message.error("Validation failed");
    }
  };
  const ViewDropdown = ({ record, navigate }) => {
    const menu = (
      <Menu>
        {/* <Menu.Item
          key="1"
          onClick={() =>
            navigate("/riceAlotmentHistory", { state: { Fill: record } })
          }
        >
          View Rice Allotted History
        </Menu.Item> */}
        <Menu.Item
          key="1"
          onClick={() => navigate("/billDetails", { state: { Fill: record } })}
        >
          View Bill details
        </Menu.Item>
        <Menu.Item
          key="2"
          onClick={() =>
            navigate("/registerDetails", { state: { Fill: record } })
          }
        >
          View Register data
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown overlay={menu} trigger={["click"]}>
        <Tag className=" bg-rgbaprimary4 text-white font-semibold flex justify-center text-center w-full cursor-pointer">
          …
        </Tag>
      </Dropdown>
    );
  };
  const columns = [
    {
      title: "उपयोगकर्ता नाम",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "मोबाइल नंबर",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "ब्लॉक ",
      dataIndex: "block",
      key: "block",
    },
    {
      title: "अवशेष चावल",
      dataIndex: "avsheshChawa",
      key: "avsheshChawa",
    },
    {
      title: "ज़िला ",
      dataIndex: "district",
      key: "district",
    },
    {
      title: "आधार नंबर ",
      dataIndex: "AdharNo",
      key: "AdharNo",
    },
    {
      title: "पैन नंबर",
      dataIndex: "PAN",
      key: "PAN",
    },
    // {
    //   title: "छात्रों की संख्या ",
    //   dataIndex: "numberOfStudents",
    //   key: "numberOfStudents",
    // },
    {
      title: "स्कूल के नाम",
      dataIndex: "schoolName",
      key: "schoolName",
      render: (data, record) => (
        <Tag className="rounded-md bg-rgbaprimary4 text-white font-semibold flex justify-center text-center w-full">
          {record.schoolName?? " ---"}
        </Tag>
      ),
    },
    {
      title: "स्कूल का प्रकार",
      dataIndex: "schoolType",
      key: "schoolType",
      render: (data, record) => (
        <p className="rounded-md justify-center text-center  cursor-pointer">
          {record.schoolType?? "---"}
        </p>
      ),
    },
    {
      title: "सृजित दिनांक",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data, record) => (
        <p className="rounded-md justify-center text-center  cursor-pointer">
          {ExtractDate(record.createdAt)} • {ExtractTime(record.createdAt)}
        </p>
      ),
    },
    // {
    //   title: "आवंटित चावल",
    //   dataIndex: "prapt_chawal",
    //   key: "prapt_chawal",
    //   editable: true,
    //   render: (text, record) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <Form.Item
    //         name="prapt_chawal"
    //         style={{ margin: 0 }}
    //         rules={[{ required: true, message: "Please Input prapt_chawal!" }]}
    //       >
    //         <InputNumber defaultValue={record.prapt_chawal} />
    //       </Form.Item>
    //     ) : (
    //       <p onClick={() => edit(record)}>{record.prapt_chawal}</p>
    //     );
    //   },
    // },
    // {
    //   title: "operation",
    //   dataIndex: "operation",
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <span>
    //         <Typography.Link
    //           onClick={() => save(record._id)}
    //           style={{ marginRight: 8 }}
    //         >
    //           Save
    //         </Typography.Link>
    //         <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
    //           <a>Cancel</a>
    //         </Popconfirm>
    //       </span>
    //     ) : (
    //       <Typography.Link onClick={() => edit(record)}>Edit</Typography.Link>
    //     );
    //   },
    // },
    {
      title: "View",
      dataIndex: "navigate",
      key: "navigate",
      render: (data, record) => (
        <ViewDropdown record={record} navigate={navigate} />
      ),
    },
  ];

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "prapt_chawal" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <>
          <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
            School Details
          </div>

          <div>
            <Divider
              orientation="center"
              className="fs-4 theme-darkt fw-semi-bold"
            >
              School Details
            </Divider>
          </div>
          <div className="flex items-end mb-4">
            <Search
              placeholder="Enter search term..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ width: "25%" }}
            />
          </div>
          <div
            style={{ maxHeight: "500px", overflow: "auto" }}
            className="bg-white"
          >
            <Form form={form} component={false}>
              <Table
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered={true}
                columns={mergedColumns}
                dataSource={data}
                pagination={false}
                className="w-screen sm:w-screen"
                size="small"
              />
            </Form>
            <Pagination
              className="mt-2 w-full"
              onChange={handlePageChange}
              current={currentPage}
              defaultCurrent={1}
              total={10 * totalPages}
              showSizeChanger={false}
            />
          </div>
        </>
      )}
    </>
  );
};

export default SchoolDetails;
