import { Button, Modal } from "antd";
import React, { useRef } from "react";
import "./bill.css";
import html2pdf from "html2pdf.js";
import { DownloadOutlined, PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";

const GenerateBill = ({ open, setOpen, filledData }) => {
  

  const componentRef = useRef();
  return (
    <div>
      <Modal
        title="Generate Bill"
        open={open}
        maskClosable={false}
        footer={null}
        onCancel={() => {
          setOpen(false);
        }}
      >
        <div className="bill p-1 text-xs" ref={componentRef}>
          <header>
            <h1 className="font-semibold">प्रपत्र - "क"</h1>
            <h2 className="font-bold text-lg">विद्यालय से संबंधित प्रतिवेदन</h2>
            <h3 className="font-bold text-base">
              प्राप्ति एवं व्यय संबंधी माह का नाम :{" "}
              <span className="font-bold text-base">
                {filledData?.Month ?? ""}
              </span>
            </h3>
          </header>
          <section className="school-info">
            <div>
              <h3>
                विद्यालय का नाम :{" "}
                <span className="font-bold text-base">
                  {filledData?.SchoolId?.schoolName ?? ""}
                </span>
              </h3>
              <h3>
                प्रखंड का नाम :{" "}
                <span className="font-bold text-base">
                  {filledData?.BillTitleDetails?.block}
                </span>
              </h3>
              <h3>
                जिला का नाम :
                <span className="font-bold text-base">
                  {filledData?.BillTitleDetails?.district}
                </span>
              </h3>
            </div>
          </section>
          <section className="summary">
            <p className="flex justify-center text-base font-bold ">
              वित्तीय विवरणी
            </p>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th>राशि</th>
                  <th></th>
                  <th></th>
                  <th>राशि</th>
                </tr>
                <tr>
                  <th>A</th>
                  <th>प्रारंभिक शेष</th>
                  <th></th>
                  <th>C</th>
                  <th>व्यय (पोषाहार पंजी की प्रविष्टि के अनुरूप भरा जाय)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>बैंक में :-</td>
                  <td></td>
                  <td>1</td>
                  <td>परिवर्तन मूल्य (I-V)</td>
                  <td></td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>हाथ में :-</td>
                  <td></td>
                  <td>(i)</td>
                  <td>दाल</td>
                  <td className="tdAlign">
                    {(
                      filledData?.PrimaryClassBillData?.Dal_total_amount ?? 0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>B</td>
                  <td>प्राप्ति</td>
                  <td></td>
                  <td>(ii)</td>
                  <td> मसाला / नमक</td>
                  <td className="tdAlign">
                    {(
                      filledData?.PrimaryClassBillData
                        ?.Masala_Namak_total_amount ?? 0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>1</td>
                  <td>परिवर्तन मूल्य (I-V)</td>
                  <td className="tdAlign">
                    {(
                      filledData?.PrimaryClassBillData?.Total_expenses_amount ??
                      0
                    ).toFixed(2)}
                  </td>

                  <td>(iii)</td>
                  <td>तेल</td>
                  <td className="tdAlign">
                    {(
                      filledData?.PrimaryClassBillData?.Tel_total_amount ?? 0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>परिवर्तन मूल्य (VI-VIII)</td>
                  <td className="tdAlign">
                    {(
                      filledData?.MiddleClassBillData?.Total_expenses_amount ??
                      0
                    ).toFixed(2)}
                  </td>
                  <td>(iv)</td>
                  <td>सब्जी</td>
                  <td className="tdAlign">
                    {(
                      filledData?.PrimaryClassBillData
                        ?.SabjiSoyabeanSaladTotalAmount ?? 0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>रसोइया-सह- सहायक मानदेय</td>
                  <td></td>
                  <td>(v)</td>
                  <td>जलावन</td>
                  <td className="tdAlign">
                    {(
                      filledData?.PrimaryClassBillData?.Jalawan_total_amount ??
                      0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>रसोई भंडार गृह-निर्माण</td>
                  <td></td>
                  <td>(vi)</td>
                  <td>कुल योग</td>
                  <td className="tdAlign">
                    {" "}
                    {(
                      filledData?.PrimaryClassBillData?.Total_expenses_amount ??
                      0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>बर्तन सामग्री</td>
                  <td></td>
                  <td>2</td>
                  <td>परिवर्तन मूल्य (VI-VIII)</td>
                  <td></td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>अन्य (कृप्या मद का नाम इंगित करें)</td>
                  <td></td>
                  <td>(i)</td>
                  <td>दाल</td>
                  <td className="tdAlign">
                    {(
                      filledData?.MiddleClassBillData?.Dal_total_amount ?? 0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>(ii)</td>
                  <td>मसाला / नमक</td>
                  <td className="tdAlign">
                    {(
                      filledData?.MiddleClassBillData
                        ?.Masala_Namak_total_amount ?? 0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>(iii)</td>
                  <td>तेल</td>
                  <td className="tdAlign">
                    {(
                      filledData?.MiddleClassBillData?.Tel_total_amount ?? 0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>(iv)</td>
                  <td>सब्जी</td>
                  <td className="tdAlign">
                    {(
                      filledData?.MiddleClassBillData
                        ?.SabjiSoyabeanSaladTotalAmount ?? 0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>(v)</td>
                  <td>जलावन</td>
                  <td className="tdAlign">
                    {(
                      filledData?.MiddleClassBillData?.Jalawan_total_amount ?? 0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>(vi)</td>
                  <td>कुल योग</td>
                  <td className="tdAlign">
                    {(
                      filledData?.MiddleClassBillData?.Total_expenses_amount ??
                      0
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>3</td>
                  <td>रसोइया-सह- सहायक मानदेय</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>4</td>
                  <td>रसोई भंडार गृह-निर्माण</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>5</td>
                  <td>बर्तन सामग्री</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>6</td>
                  <td>अन्य (कृप्या मद का नाम इंगित करें)</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>कुल योग</td>
                  <td className="tdAlign">
                    {(
                      (filledData?.PrimaryClassBillData
                        ?.Total_expenses_amount ?? 0) +
                      (filledData?.MiddleClassBillData?.Total_expenses_amount ??
                        0)
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>D</td>
                  <td>अंतिम शेष:-</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>बैंक में</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>हाथ में</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>कुल योग</td>
                  <td className="tdAlign">
                    {(
                      (filledData?.PrimaryClassBillData
                        ?.Total_expenses_amount ?? 0) +
                      (filledData?.MiddleClassBillData?.Total_expenses_amount ??
                        0)
                    ).toFixed(2)}
                  </td>

                  <td></td>
                  <td>कुल योग</td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <br />
            <table>
              <caption className="text-base font-bold  ">
                सामग्री की विवरणी
              </caption>
              <thead>
                <tr>
                  <th></th>
                  <th>प्राप्ति</th>
                  <th>मात्रा</th>
                  <th></th>
                  <th>खपत</th>
                  <th>मात्रा</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>E</td>
                  <td>प्रारंभिक शेष खाद्यान्न (चावल)</td>
                  <td className="tdAlign">
                    {(
                      filledData?.BillTitleDetails?.Available_rice ?? 0
                    ).toFixed(2)}
                  </td>
                  <td>G</td>
                  <td>खाद्यान्न (चावल) क्विंटल /किलो</td>
                  <td className="tdAlign">
                    {(
                      (filledData?.PrimaryClassBillData
                        ?.Used_Rice_total_Quantity_kg ?? 0) +
                      (filledData?.MiddleClassBillData
                        ?.Used_Rice_total_Quantity_kg ?? 0)
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>F</td>
                  <td>प्राप्ति :-</td>
                  <td className="tdAlign">
                    {(filledData?.BillTitleDetails?.Alloted_rice ?? 0).toFixed(
                      2
                    )}
                  </td>
                  <td>H</td>
                  <td>अन्तिम शेष-</td>
                  <td className="tdAlign">
                    {(
                      (filledData?.BillTitleDetails?.Total_available_rice ??
                        0) -
                      (filledData?.PrimaryClassBillData
                        ?.Used_Rice_total_Quantity_kg ?? 0) +
                      (filledData?.MiddleClassBillData
                        ?.Used_Rice_total_Quantity_kg ?? 0)
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>कुल खाद्यान्न (चावल) क्विंटल /किलो</td>
                  <td className="tdAlign">
                    {(
                      (filledData?.BillTitleDetails?.Available_rice ?? 0) +
                      (filledData?.BillTitleDetails?.Alloted_rice ?? 0)
                    ).toFixed(2)}
                  </td>

                  <td></td>
                  <td>कुल खाद्यान्न (चावल) क्विंटल /किलो</td>
                  <td className="tdAlign">
                    {(
                      (filledData?.PrimaryClassBillData
                        ?.Used_Rice_total_Quantity_kg ?? 0) +
                      (filledData?.MiddleClassBillData
                        ?.Used_Rice_total_Quantity_kg ?? 0) +
                      ((filledData?.BillTitleDetails?.Total_available_rice ??
                        0) -
                        ((filledData?.PrimaryClassBillData
                          ?.Used_Rice_total_Quantity_kg ?? 0) +
                          (filledData?.MiddleClassBillData
                            ?.Used_Rice_total_Quantity_kg ?? 0)))
                    ).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <br />
            <table>
              <caption className="text-base font-bold ">
                अण्डा / मौसमी फल उपयोगिता प्रमाण पत्र
              </caption>

              <tbody>
                <tr>
                  <td></td>
                  <td>अण्डा / मौसमी फल में पूर्व माह का अवशेष राशि</td>
                  <td></td>
                  <td></td>
                  <td>अण्डा / मौसमी फल मद में इस माह में प्राप्त राशि</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>अण्डा / मौसमी फल मद में इस माह में व्यय की गई राशि</td>
                  <td className="tdAlign">
                    {(
                      5 *
                      ((filledData?.PrimaryClassBillData?.fridayStudentCount ??
                        0) +
                        (filledData?.PrimaryClassBillData?.sundayStudentCount ??
                          0) +
                        (filledData?.MiddleClassBillData?.fridayStudentCount ??
                          0) +
                        (filledData?.MiddleClassBillData?.sundayStudentCount ??
                          0))
                    ).toFixed(2)}
                  </td>
                  <td></td>
                  <td>अण्डा / मौसमी फल में अवशेष राशि</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td>अण्डा / मौसमी फल माह में दिये गये दिनों की संख्या</td>
                  <td className="tdAlign">
                    {(filledData?.PrimaryClassBillData?.fridayCount ?? 0) +
                      (filledData?.PrimaryClassBillData?.sundayCount ?? 0) +
                      (filledData?.MiddleClassBillData?.fridayCount ?? 0) +
                      (filledData?.MiddleClassBillData?.sundayCount ?? 0)}
                  </td>

                  <td></td>
                  <td>अण्डा / मौसमी फल से लाभान्वित कुल बच्चों की संख्या</td>
                  <td className="tdAlign">
                    {" "}
                    {(filledData?.PrimaryClassBillData?.fridayStudentCount ??
                      0) +
                      (filledData?.PrimaryClassBillData?.sundayStudentCount ??
                        0) +
                      (filledData?.MiddleClassBillData?.fridayStudentCount ??
                        0) +
                      (filledData?.MiddleClassBillData?.sundayStudentCount ??
                        0)}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
          <footer>
            <p>
              प्रमाणित किया जाता है कि उपर्युक्त वित्तीय एवं सामग्री की उपयोगिता
              विवरणी विद्यालय में संधारित एवं उपलब्ध पोषाहार पंजी के आधार पर
              तैयार की गई है जो पूर्ण रूपेण सही है।
            </p>
          </footer>
          <div className="flex justify-between m-4">
            <p className="text-base">प्रखण्ड साधनसेवी का हस्ताक्षर</p>
            <p className="text-base">प्रधानध्यापक का हस्ताक्षर</p>
          </div>
        </div>
        <div className="flex justify-center p-1">
          <ReactToPrint
            trigger={() => (
              <Button icon={<PrinterOutlined />}>Print this out!</Button>
            )}
            content={() => componentRef.current}
          />
          {/* <Button
            className="w-96 bg-primary text-white font-semibold"
            onClick={() => {
              handleDownload();
              setOpen(false);
            }}
          >
            {" "}
            <DownloadOutlined /> Download Bill
          </Button> */}
        </div>
      </Modal>
    </div>
  );
};
export default GenerateBill;
