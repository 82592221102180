import { useEffect, useState } from "react";
import { Divider, Table, Tag } from "antd";
import { Pagination, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { riceAllotment_detail } from "../../../services/apiFunctions";
const RiceAlotmentHistory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const location = useLocation(true);
  const Details = location.state?.Fill;
  function RiceAllotmentHistory() {
    setIsLoading(true);
    riceAllotment_detail(`${Details._id}&page=${currentPage}&limit=10`)
      .then((response) => {
        setData(response.data?.result?.data);
        setcurrentPage(response.data?.result?.currentPage);
        setTotalPages(response.data?.result?.totalPages);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    RiceAllotmentHistory(currentPage);
  }, [currentPage]);

  const handlePageChange = (page, pageSize) => {
    setcurrentPage(page);
  };

  const columns = [
    {
      title: "Month",
      dataIndex: "Month",
      key: "Month",
      render: (data, record) => {
        return (
          <Tag className="text-xs flex w-1/2 justify-center">
            {" "}
            {record?.Month}
          </Tag>
        );
      },
    },
    {
      title: "Year",
      dataIndex: "Year",
      key: "Year",
      render: (data, record) => {
        return (
          <Tag className="text-xs flex w-1/2 justify-center">
            {" "}
            {record?.Year}
          </Tag>
        );
      },
    },
    {
      title: " Available Rice (Kg)",
      dataIndex: "available_rice(kg)",
      key: "available_rice(kg)",
    },
    {
      title: " Alloted_rice(kg)",
      dataIndex: "Alloted_rice(kg)",
      key: "Alloted_rice(kg)",
    },
  ];
  return (
    <>
      {isLoading ? (
        <div className="text-center mt-52">
          <Spin size="md" />
        </div>
      ) : (
        <>
          <div className="bg-rgbaprimary9 mb-2 text-white font-bold p-2 text-lg">
            Rice Allotment History
          </div>

          <div>
            <Divider
              orientation="center"
              className="fs-4  theme-darkt fw-semi-bold"
            >
              Rice Allotment History
            </Divider>
          </div>
          <div
            style={{ maxHeight: "550px", overflow: "auto" }}
            className="bg-white"
          >
            <Table
            size="small"
              bordered={true}
              columns={columns}
              dataSource={data}
              pagination={false}
              className="w-screen sm:w-screen "
            />
            <Pagination
              className="mt-2  w-full "
              onChange={handlePageChange}
              current={currentPage}
              defaultCurrent={1}
              total={10 * totalPage}
              showSizeChanger={false}
            />
          </div>
        </>
      )}
    </>
  );
};

export default RiceAlotmentHistory;
