import { message } from 'antd';
import axios from 'axios';
import { messageConfiguration } from '../../utils';
const ApiService = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BASE_URL,
})
// Add a request interceptor
ApiService.interceptors.request.use((config) => {
  // Add the timestamp to the headers of the API request
  config.headers['X-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return config;
});

// Add a response interceptor
ApiService.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const { status } = error?.response;
    if(status && status ===401){
      localStorage.clear();
      message.open(messageConfiguration("error", "Session Timeout ",3))
     window.location.href="/";
    }
    if(status && status>=400 && status<=500)
    {
      
      message.open(messageConfiguration("error",error.response.data.message,3))
    }
    
  }
);

export default ApiService;